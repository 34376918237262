import React from 'react';
import { Route, Routes, Link } from "react-router-dom";

import PrivateRoute from '../Authentication/PrivateRoute';

import Login from '../Authentication/Login';

import Dashboard from '../Dashboard/Dashboard';

import ListParzellen from  '../Parzellen/List';
import ShowParzelle from '../Parzellen/Show';
import CreateParzelle from '../Parzellen/Create';
import UpdateParzelle from '../Parzellen/Update';

import ListPaechter from '../Paechter/List';
import {ListArchiviertePaechter} from '../Paechter/List';
import ShowPaechter from '../Paechter/Show';
import CreatePaechter from '../Paechter/Create';
import UpdatePaechter from '../Paechter/Update';


import ListMobilheime from '../Mobilheime/List';
import ShowMobilheim from '../Mobilheime/Show';
import CreateMobilheim from '../Mobilheime/Create';
import UpdateMobilheim from '../Mobilheime/Update';

import ListZahlungen from '../Zahlungen/List';
import ShowZahlung from '../Zahlungen/Show';
import CreateZahlung from '../Zahlungen/Create';
import UpdateZahlung from '../Zahlungen/Update';

import ListPachtpreise from '../Pachtpreise/List';
import CreatePachtpreis from '../Pachtpreise/Create';
import UpdatePachtpreis from '../Pachtpreise/Update';

import ListPacht from '../Pacht/List';
import CreatePacht from '../Pacht/Create';
import UpdatePacht from '../Pacht/Update';
import PachtBoard from '../Pacht/Board/Board';

import ListZaehler from '../Zaehler/List';
import ShowZaehler from '../Zaehler/Show';
import CreateZaehler from '../Zaehler/Create';
import UpdateZaehler from '../Zaehler/Update';

import ListZaehlerstaende from '../Zaehlerstaende/List';
import ShowZaehlerstand from '../Zaehlerstaende/Show';
import CreateZaehlerstand from '../Zaehlerstaende/Create';
import UpdateZaehlerstand from '../Zaehlerstaende/Update';

import ListEnergiepreise from '../Energiepreise/List';
import CreateEnergiepreis from '../Energiepreise/Create';
import UpdateEnergiepreis from '../Energiepreise/Update';

import ListNebenkosten from '../Nebenkosten/List';
//import ShowNebenkosten from '../Nebenkosten/Show';
//<Route path="/nebenkosten/:id/show" element={ <PrivateRoute><ShowNebenkosten /></PrivateRoute> } />
import CreateNebenkosten from '../Nebenkosten/Create';
import UpdateNebenkosten from '../Nebenkosten/Update';

import ListPachtrechnungen from '../Pachtrechnungen/List';
import CreatePachtrechnung from '../Pachtrechnungen/Create';
import DeletePachtrechnung from '../Pachtrechnungen/Delete';

import ListEnergierechnungen from '../Energierechnungen/List';
import CreateEnergierechnung from '../Energierechnungen/Create';

import ListNebenkostenrechnungen from '../Nebenkostenrechnungen/List';
import CreateNebenkostenrechnung from '../Nebenkostenrechnungen/Create';

import ListGesamtrechnungen from '../Gesamtrechnungen/List';
import CreateGesamtrechnung from '../Gesamtrechnungen/Create';

import ListRechnungslaeufe from '../Rechnungslaeufe/List';
import CreateRechnungslauf from '../Rechnungslaeufe/Create';
import DeleteRechnungslauf from '../Rechnungslaeufe/Delete';

import ListSonstigeRechnungen from '../SonstigeRechnungen/List';
import CreateSonstigeRechnung from '../SonstigeRechnungen/Create';
import StornoSonstigeRechnung from '../SonstigeRechnungen/Storno';

import ListEnergieverbraeuche from '../Energieverbraeuche/List';
import CalcEnergieverbraeuche from '../Energieverbraeuche/Calc';

import ListEinzelkosten from '../Einzelkosten/List';
import CreateEinzelkosten from '../Einzelkosten/Create';
import UpdateEinzelkosten from '../Einzelkosten/Update';

import ListGemeinkosten from '../Gemeinkosten/List';
import CreateGemeinkosten from '../Gemeinkosten/Create';
import UpdateGemeinkosten from '../Gemeinkosten/Update';

import ListGutschriften from '../Gutschriften/List';
import CreateGutschrift from '../Gutschriften/Create';
import StornoGutschrift from '../Gutschriften/Storno';

import CreateZahlungsmodus from '../Zahlungsmodus/Create';

import { ListMahnungen, ListArchivierteMahnungen } from '../Mahnungen/List';

import Uebersicht from '../Listen/Uebersicht';
import Telefonliste from '../Listen/Telefonliste';
import Adressliste from '../Listen/Adressliste';
import Standortliste from '../Listen/Standorte';
import EichdatenlisteStrom from '../Listen/Eichdaten_Strom';
import EichdatenlisteWasser from '../Listen/Eichdaten_Wasser';
import EichdatenlisteGas from '../Listen/Eichdaten_Gas';
import GeplanteGasdruckpruefungen from '../Listen/GeplanteGasdruckpruefungen';
import Auflagenfristen from '../Listen/Auflagenfristen';
import Briefkaesten from '../Listen/Briefkaesten';
import Debitoren from '../Listen/Debitoren';
import FaelligeGasdruckpruefungen from '../Listen/FaelligeGasdruckpruefungen';
import Kennzeichen from '../Listen/KFZ_Kennzeichen';
import Zaehlerstaende from '../Listen/Zaehlerstaende';
import Energieverbraeuche from '../Listen/Energieverbraeuche';


import Statistiken from '../Statistiken/Statistiken';

import ListLogs from '../Logs/List';




export default function Body() {
    
    return (
        <div className="main">
            
            <Routes>
                <Route path="/" element={ <PrivateRoute><Dashboard /></PrivateRoute> } />

                <Route path="/paechter" element={ <PrivateRoute><ListPaechter /></PrivateRoute> }/>
                <Route path="/paechter/archiviert" element={ <PrivateRoute><ListArchiviertePaechter /></PrivateRoute> }/>
                <Route path="/paechter/:id/show" element={ <PrivateRoute><ShowPaechter /></PrivateRoute> } />
                <Route path="/paechter/create" element={ <PrivateRoute><CreatePaechter /></PrivateRoute> } />
                <Route path="/paechter/:id/update" element={ <PrivateRoute><UpdatePaechter /></PrivateRoute> } />
                
                <Route path="/mobilheime" element={ <PrivateRoute><ListMobilheime /></PrivateRoute> } />
                <Route path="/mobilheim/:id/show" element={ <PrivateRoute><ShowMobilheim /></PrivateRoute> } />
                <Route path="/mobilheim/create" element={ <PrivateRoute><CreateMobilheim /></PrivateRoute> } />
                <Route path="/mobilheim/:id/update" element={ <PrivateRoute><UpdateMobilheim /></PrivateRoute> } />

                <Route path="/parzellen" element={ <PrivateRoute><ListParzellen /></PrivateRoute> } />
                <Route path="/parzelle/:id/show" element={ <PrivateRoute><ShowParzelle /></PrivateRoute> } />
                <Route path="/parzelle/create" element={ <PrivateRoute><CreateParzelle /></PrivateRoute> } />
                <Route path="/parzelle/:id/update" element={ <PrivateRoute><UpdateParzelle /></PrivateRoute> } />

                <Route path="/pachtpreise" element={ <PrivateRoute><ListPachtpreise /></PrivateRoute> } />
                <Route path="/pachtpreis/create" element={ <PrivateRoute><CreatePachtpreis /></PrivateRoute> } />
                <Route path="/pachtpreis/:id/update" element={ <PrivateRoute><UpdatePachtpreis /></PrivateRoute> } />
                <Route path="/pachtpreise" element={ <PrivateRoute><ListPachtpreise /></PrivateRoute> } />

                <Route path="/pacht" element={ <PrivateRoute><ListPacht /></PrivateRoute> } />
                <Route path="/pacht/create" element={ <PrivateRoute><CreatePacht /></PrivateRoute> } />
                <Route path="/pacht/:id/update" element={ <PrivateRoute><UpdatePacht /></PrivateRoute> } />
                <Route path="/pacht/:id/board" element={ <PrivateRoute><PachtBoard /></PrivateRoute> } />

                <Route path="/zahlungsmodus/:id/update" element={ <PrivateRoute><CreateZahlungsmodus /></PrivateRoute> } />

                <Route path="/energie/zaehler" element={ <PrivateRoute><ListZaehler /></PrivateRoute> } />
                <Route path="/energie/zaehler/:id/show" element={ <PrivateRoute><ShowZaehler /></PrivateRoute> } />
                <Route path="/energie/zaehler/create" element={ <PrivateRoute><CreateZaehler /></PrivateRoute> } />
                <Route path="/energie/zaehler/:id/update" element={ <PrivateRoute><UpdateZaehler /></PrivateRoute> } />

                <Route path="/energie/zaehlerstaende" element={ <PrivateRoute><ListZaehlerstaende /></PrivateRoute> } />
                <Route path="/energie/zaehlerstaende/:id/show" element={ <PrivateRoute><ShowZaehlerstand /></PrivateRoute> } />
                <Route path="/energie/zaehlerstaende/create" element={ <PrivateRoute><CreateZaehlerstand /></PrivateRoute> } />
                <Route path="/energie/zaehlerstaende/:id/update" element={ <PrivateRoute><UpdateZaehlerstand /></PrivateRoute> } />

                <Route path="/energie/energiepreise" element={ <PrivateRoute><ListEnergiepreise /></PrivateRoute> } />
                <Route path="/energie/energiepreis/create" element={ <PrivateRoute><CreateEnergiepreis /></PrivateRoute> } />
                <Route path="/energie/energiepreis/:id/update" element={ <PrivateRoute><UpdateEnergiepreis /></PrivateRoute> } />

                <Route path="/energie/energieverbraeuche" element={ <PrivateRoute><ListEnergieverbraeuche /></PrivateRoute> } />
                <Route path="/energie/energieverbraeuche/berechnen" element={ <PrivateRoute><CalcEnergieverbraeuche /></PrivateRoute> } />

                <Route path="/nebenkosten" element={ <PrivateRoute><ListNebenkosten /></PrivateRoute> } />
                <Route path="/nebenkosten/create" element={ <PrivateRoute><CreateNebenkosten /></PrivateRoute> } />
                <Route path="/nebenkosten/:id/update" element={ <PrivateRoute><UpdateNebenkosten /></PrivateRoute> } />

                <Route path="/nebenkosten/gemeinkosten" element={ <PrivateRoute><ListGemeinkosten /></PrivateRoute> } />
                <Route path="/nebenkosten/gemeinkosten/create" element={ <PrivateRoute><CreateGemeinkosten /></PrivateRoute> } />
                <Route path="/nebenkosten/gemeinkosten/:id/update" element={ <PrivateRoute><UpdateGemeinkosten /></PrivateRoute> } />

                <Route path="/nebenkosten/einzelkosten" element={ <PrivateRoute><ListEinzelkosten /></PrivateRoute> } />
                <Route path="/nebenkosten/einzelkosten/create" element={ <PrivateRoute><CreateEinzelkosten /></PrivateRoute> } />
                <Route path="/nebenkosten/einzelkosten/:id/update" element={ <PrivateRoute><UpdateEinzelkosten /></PrivateRoute> } />
                
                <Route path="/rechnungen/pachtrechnungen" element={ <PrivateRoute><ListPachtrechnungen /></PrivateRoute> } />
                <Route path="/rechnungen/pachtrechnungen/create" element={ <PrivateRoute><CreatePachtrechnung /></PrivateRoute> } />

                <Route path="/rechnungen/energierechnungen" element={ <PrivateRoute><ListEnergierechnungen /></PrivateRoute> } />
                <Route path="/rechnungen/energierechnungen/create" element={ <PrivateRoute><CreateEnergierechnung /></PrivateRoute> } />
                
                <Route path="/rechnungen/nebenkostenrechnungen" element={ <PrivateRoute><ListNebenkostenrechnungen /></PrivateRoute> } />
                <Route path="/rechnungen/nebenkostenrechnungen/create" element={ <PrivateRoute><CreateNebenkostenrechnung /></PrivateRoute> } />

                <Route path="/rechnungen/gesamtrechnungen" element={ <PrivateRoute><ListGesamtrechnungen /></PrivateRoute> } />
                <Route path="/rechnungen/gesamtrechnungen/create" element={ <PrivateRoute><CreateGesamtrechnung /></PrivateRoute> } />

                <Route path="/rechnungen/rechnungslaeufe" element={ <PrivateRoute><ListRechnungslaeufe /></PrivateRoute> } />
                <Route path="/rechnungen/rechnungslauf/create" element={ <PrivateRoute><CreateRechnungslauf /></PrivateRoute> } />

                <Route path="/rechnungen/sonstige-rechnungen" element={ <PrivateRoute><ListSonstigeRechnungen /></PrivateRoute> } />
                <Route path="/rechnungen/sonstige-rechnungen/create" element={ <PrivateRoute><CreateSonstigeRechnung /></PrivateRoute> } />
                <Route path="/rechnungen/sonstige-rechnungen/:id/storno" element={ <PrivateRoute><StornoSonstigeRechnung /></PrivateRoute> } />

                <Route path="/zahlungen" element={ <PrivateRoute><ListZahlungen /></PrivateRoute> } />
                <Route path="/zahlung/:id/show" element={ <PrivateRoute><ShowZahlung /></PrivateRoute> } />
                <Route path="/zahlung/create" element={ <PrivateRoute><CreateZahlung /></PrivateRoute> } />
                <Route path="/zahlung/:id/update" element={ <PrivateRoute><UpdateZahlung /></PrivateRoute> } />

                <Route path="/mahnungen" element={ <PrivateRoute><ListMahnungen /></PrivateRoute> } />
                <Route path="/archivierte-mahnungen" element={ <PrivateRoute><ListArchivierteMahnungen /></PrivateRoute> } />

                <Route path="/gutschriften" element={ <PrivateRoute><ListGutschriften /></PrivateRoute> } />
                <Route path="/gutschriften/create" element={ <PrivateRoute><CreateGutschrift /></PrivateRoute> } />
                <Route path="/gutschriften/:id/storno" element={ <PrivateRoute><StornoGutschrift /></PrivateRoute> } />
                
                <Route path="/listen" element={ <PrivateRoute><Uebersicht /></PrivateRoute> } />
                <Route path="/listen/telefonnummern" element={ <PrivateRoute><Telefonliste /></PrivateRoute> } />
                <Route path="/listen/adressen" element={ <PrivateRoute><Adressliste /></PrivateRoute> } />
                <Route path="/listen/standorte" element={ <PrivateRoute><Standortliste /></PrivateRoute> } />
                <Route path="/listen/eichdaten/strom" element={ <PrivateRoute><EichdatenlisteStrom /></PrivateRoute> } />
                <Route path="/listen/eichdaten/wasser" element={ <PrivateRoute><EichdatenlisteWasser /></PrivateRoute> } />
                <Route path="/listen/eichdaten/gas" element={ <PrivateRoute><EichdatenlisteGas /></PrivateRoute> } />
                <Route path="/listen/geplante-gasdruckpruefungen" element={ <PrivateRoute><GeplanteGasdruckpruefungen /></PrivateRoute> } />
                <Route path="/listen/auflagenfristen" element={ <PrivateRoute><Auflagenfristen /></PrivateRoute> } />
                <Route path="/listen/briefkaesten" element={ <PrivateRoute><Briefkaesten /></PrivateRoute> } />
                <Route path="/listen/debitoren" element={ <PrivateRoute><Debitoren /></PrivateRoute> } />
                <Route path="/listen/faellige-gasdruckpruefungen" element={ <PrivateRoute><FaelligeGasdruckpruefungen /></PrivateRoute> } />
                <Route path="/listen/zaehlerstaende" element={ <PrivateRoute><Zaehlerstaende /></PrivateRoute> } />
                <Route path="/listen/kennzeichen" element={ <PrivateRoute><Kennzeichen /></PrivateRoute> } />
                <Route path="/listen/energieverbraeuche/:id" element={ <PrivateRoute><Energieverbraeuche /></PrivateRoute> } />

                <Route path="/logs/:rechnungslaufId/list" element={ <PrivateRoute><ListLogs /></PrivateRoute> } />

                <Route path="/statistiken" element={ <PrivateRoute><Statistiken /></PrivateRoute> } />
                
            </Routes>
        </div>
    )
}