import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_DATEN_LISTE_ZAEHLERSTAENDE } from './graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 10,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30, // Set a minimum height for all rows
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 12,
    },
    backgroundStrom: {
        backgroundColor: '#990000',
        color: '#fff',
        justifyContent: 'center',
    },
    backgroundGas: {
        backgroundColor: '#004d4d',
        color: '#fff',
        justifyContent: 'center',
    },
    backgroundWasser: {
        backgroundColor: '#002699',
        color: '#fff',
        justifyContent: 'center',
    },
    row: {
        flexDirection: 'row',
        padding: 0, // No padding in rows
    },
    evenRow: {
        backgroundColor: '#f0f0f0', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#ffffff', // White for odd rows
    },
    cell: {
        padding: 5, // Increase padding for spacing inside cells
        borderTop: '1px solid black', // Top border for all cells
        borderBottom: '1px solid black', // **ADD BOTTOM BORDER**
        borderRight: '1px solid black', // Right border for all cells
        justifyContent: 'center', // Align content vertically
        textAlign: 'left', // Align text to the left
    },
    firstCell: {
        borderLeft: '1px solid black', // Ensure left border for the first cell
    },
    lastCell: {
        borderRight: '1px solid black', // Ensure right border for the last cell
    },
    name: {
        width: '12%',
    },
    vorname: {
        width: '10%',
    },
    parzelle: {
        width: '12%',
    },
    stromItem: {
        width: '5.5%',
    },
    gasItem: {
        width: '5.5%',
    },
    wasserItem: {
        width: '5.5%',
    },
});


const Zaehlerstaende = () => {
    const { loading, error, data, refetch } = useQuery(GET_DATEN_LISTE_ZAEHLERSTAENDE);

    if (loading) return <LoadingAnimation/>;

    const zaehlerstaendeList = data ? data.datenListeZaehlerstaende.map(item => ({
        name: item.paechterName,
        vorname: item.paechterVorname,
        parzelle: item.standort,
        stromAnfangsstand: item.stromAnfangsstand,
        stromStandBeiWechsel: item.stromStandBeiWechsel,
        stromStandNachWechsel: item.stromStandNachWechsel,
        stromEndstand: item.stromEndstand,
        gasAnfangsstand: item.gasAnfangsstand,
        gasStandBeiWechsel: item.gasStandBeiWechsel,
        gasStandNachWechsel: item.gasStandNachWechsel,
        gasEndstand: item.gasEndstand,
        wasserAnfangsstand: item.wasserAnfangsstand,
        wasserStandBeiWechsel: item.wasserStandBeiWechsel,
        wasserStandNachWechsel: item.wasserStandNachWechsel,
        wasserEndstand: item.wasserEndstand,
    })) : [];

    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.firstCell, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.vorname]}>Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.parzelle]}>Parzelle</Text>
                            <Text style={[styles.cell, styles.header, styles.stromItem, styles.backgroundStrom]}>S A</Text>
                            <Text style={[styles.cell, styles.header, styles.stromItem, styles.backgroundStrom]}>S BW</Text>
                            <Text style={[styles.cell, styles.header, styles.stromItem, styles.backgroundStrom]}>S NW</Text>
                            <Text style={[styles.cell, styles.header, styles.stromItem, styles.backgroundStrom]}>S E</Text>
                            <Text style={[styles.cell, styles.header, styles.gasItem, styles.backgroundGas]}>G A</Text>
                            <Text style={[styles.cell, styles.header, styles.gasItem, styles.backgroundGas]}>G BW</Text>
                            <Text style={[styles.cell, styles.header, styles.gasItem, styles.backgroundGas]}>G NW</Text>
                            <Text style={[styles.cell, styles.header, styles.gasItem, styles.backgroundGas]}>G E</Text>
                            <Text style={[styles.cell, styles.header, styles.wasserItem, styles.backgroundWasser]}>W A</Text>
                            <Text style={[styles.cell, styles.header, styles.wasserItem, styles.backgroundWasser]}>W BW</Text>
                            <Text style={[styles.cell, styles.header, styles.wasserItem, styles.backgroundWasser]}>W NW</Text>
                            <Text style={[styles.cell, styles.header, styles.wasserItem, styles.lastCell, styles.backgroundWasser]}>W E</Text>
                        </View>

                        {/* Render rows */}
                        {zaehlerstaendeList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]} wrap={false}>
                                <Text style={[styles.cell, styles.firstCell, styles.name]}>{item.name}</Text>
                                <Text style={[styles.cell, styles.vorname]}>{item.vorname}</Text>
                                <Text style={[styles.cell, styles.parzelle]}>{item.parzelle}</Text>
                                <Text style={[styles.cell, styles.stromItem]}>{item.stromAnfangsstand}</Text>
                                <Text style={[styles.cell, styles.stromItem]}>{item.stromStandBeiWechsel}</Text>
                                <Text style={[styles.cell, styles.stromItem]}>{item.stromStandNachWechsel}</Text>
                                <Text style={[styles.cell, styles.stromItem]}>{item.stromEndstand}</Text>
                                <Text style={[styles.cell, styles.gasItem]}>{item.gasAnfangsstand}</Text>
                                <Text style={[styles.cell, styles.gasItem]}>{item.gasStandBeiWechsel}</Text>
                                <Text style={[styles.cell, styles.gasItem]}>{item.gasStandNachWechsel}</Text>
                                <Text style={[styles.cell, styles.gasItem]}>{item.gasEndstand}</Text>
                                <Text style={[styles.cell, styles.wasserItem]}>{item.wasserAnfangsstand}</Text>
                                <Text style={[styles.cell, styles.wasserItem]}>{item.wasserStandBeiWechsel}</Text>
                                <Text style={[styles.cell, styles.wasserItem]}>{item.wasserStandNachWechsel}</Text>
                                <Text style={[styles.cell, styles.wasserItem, styles.lastCell]}>{item.wasserEndstand}</Text>
                            </View>
                        ))}

                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Zaehlerstaende;
