import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_PACHT } from '../Pacht/graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 20,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30, // Set a minimum height for all rows
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#ffffff', // White for odd rows
    },
    cell: {
        padding: 2,
    },
    name: {
        width: '15%',
    },
    vorname: {
        width: '15%',
    },
    anrede: {
        width: '10%',
    },
    strasse: {
        width: '15%',
    },
    plz: {
        width: '10%',
    },
    ort: {
        width: '15%',
    },
    parzelle: {
        width: '15%',
    },
    parzellengroesse: {
        width: '5%',
    },
});

const Adressliste = () => {
    const { loading, error, data, refetch } = useQuery(GET_PACHT);

    if (loading) return <LoadingAnimation/>;

    const today = new Date();

    const pachtList = data ? 
    data.pacht
        .filter(item => new Date(item.pachtende) >= today)
        .map(item => ({
            name: item.paechter.name,
            vorname: item.paechter.vorname,
            anrede: item.paechter.anrede,
            strasse: item.paechter.strasse,
            plz: item.paechter.plz,
            ort: item.paechter.ort,
            parzelle: item.parzelle.standort,
            parzellengroesse: item.parzelle.parzellengroesse,
        })) 
    : [];

    // sort by paechter    
    pachtList.sort((a, b) => {
        let paechterA = a.name.toLowerCase();
        let paechterB = b.name.toLowerCase();
        
        if (paechterA < paechterB) return -1;
        if (paechterA > paechterB) return 1;
        return 0;
    });

    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.vorname]}>Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.anrede]}>Anrede</Text>
                            <Text style={[styles.cell, styles.header, styles.strasse]}>Strasse</Text>
                            <Text style={[styles.cell, styles.header, styles.plz]}>PLZ</Text>
                            <Text style={[styles.cell, styles.header, styles.ort]}>Ort</Text>
                            <Text style={[styles.cell, styles.header, styles.parzelle]}>Parzelle</Text>
                            <Text style={[styles.cell, styles.header, styles.parzellengroesse]}>qm</Text>
                        </View>

                        {/* Render rows */}
                        {pachtList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.name]} wrap={false}>{item.name}</Text>
                                <Text style={[styles.cell, styles.vorname]} wrap={false}>{item.vorname}</Text>
                                <Text style={[styles.cell, styles.anrede]} wrap={false}>{item.anrede}</Text>
                                <Text style={[styles.cell, styles.strasse]} wrap={false}>{item.strasse}</Text>
                                <Text style={[styles.cell, styles.plz]} wrap={false}>{item.plz}</Text>
                                <Text style={[styles.cell, styles.ort]} wrap={false}>{item.ort}</Text>
                                <Text style={[styles.cell, styles.parzelle]} wrap={false}>{item.parzelle}</Text>
                                <Text style={[styles.cell, styles.parzellengroesse]} wrap={false}>{item.parzellengroesse}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Adressliste;
