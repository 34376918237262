import { gql } from '@apollo/client';


export const GET_PAECHTER = gql`
    query GetPaechter {
        paechter{
            id
            anrede
            name
            vorname
            strasse
            plz
            ort
            vorherigeMeldeadresse
            telefonFestnetz
            telefonMobil
            geburtsdatum
            namePartner
            geburtsname
            geburtsdatumPartner
            eingetragenInPachtvertrag
            ausweisnummerPaechter
            ausweisnummerPartner
            weitereKontaktpersonen
            iban
            zusatzpflege
            auflagen
            auflagenfrist
            antragPv
            pruefungPv
            trennschalterPv
            duldungen
            briefkastenNr
            sensor1
            kaufdatumSensor1
            sensor2
            kaufdatumSensor2
            magnetkarte
            ausgabedatumMagnetkarte
            gebrauchteSensoren
            kfzKennzeichen
            erschliessungskosten
            ausgeschiedenAm
            stromzaehlerart
            pachtPaechter {
                kontierungsnummer
                pachtbeginn
                pachtende
                parzelle {
                    id
                    standort
                }
            }
        }
    }
`;


export const GET_ARCHIVIERTE_PAECHTER = gql`
    query GetArchiviertePaechter {
        archiviertePaechter{
            id
            anrede
            name
            vorname
            strasse
            plz
            ort
            vorherigeMeldeadresse
            telefonFestnetz
            telefonMobil
            geburtsdatum
            namePartner
            geburtsname
            geburtsdatumPartner
            eingetragenInPachtvertrag
            ausweisnummerPaechter
            ausweisnummerPartner
            weitereKontaktpersonen
            iban
            zusatzpflege
            auflagen
            auflagenfrist
            antragPv
            pruefungPv
            trennschalterPv
            duldungen
            briefkastenNr
            sensor1
            kaufdatumSensor1
            sensor2
            kaufdatumSensor2
            magnetkarte
            ausgabedatumMagnetkarte
            gebrauchteSensoren
            kfzKennzeichen
            erschliessungskosten
            ausgeschiedenAm
            stromzaehlerart
            pachtPaechter {
                pachtbeginn
                parzelle {
                    id
                    standort
                }
            }
        }
    }
`;


export const GET_ALLE_PAECHTER = gql`
    query GetAllePaechter {
        paechter{
            id
            anrede
            name
            vorname
            strasse
            plz
            ort
            vorherigeMeldeadresse
            telefonFestnetz
            telefonMobil
            geburtsdatum
            namePartner
            geburtsname
            geburtsdatumPartner
            eingetragenInPachtvertrag
            ausweisnummerPaechter
            ausweisnummerPartner
            weitereKontaktpersonen
            iban
            zusatzpflege
            auflagen
            auflagenfrist
            antragPv
            pruefungPv
            trennschalterPv
            duldungen
            briefkastenNr
            sensor1
            kaufdatumSensor1
            sensor2
            kaufdatumSensor2
            magnetkarte
            ausgabedatumMagnetkarte
            gebrauchteSensoren
            kfzKennzeichen
            erschliessungskosten
            ausgeschiedenAm
            stromzaehlerart
            pachtPaechter {
                pachtbeginn
                parzelle {
                    id
                    standort
                }
            }
        }
    }
`;


export const GET_ONE_PAECHTER = gql`
    query GetOnePaechter($pachtID: ID!) {
        onePaechter(pachtId: $pachtID) {
            id
            anrede
            name
            vorname
            strasse
            plz
            ort
            vorherigeMeldeadresse
            telefonFestnetz
            telefonMobil
            geburtsdatum
            namePartner
            geburtsname
            geburtsdatumPartner
            eingetragenInPachtvertrag
            ausweisnummerPaechter
            ausweisnummerPartner
            weitereKontaktpersonen
            iban
            zusatzpflege
            auflagen
            auflagenfrist
            antragPv
            pruefungPv
            trennschalterPv
            duldungen
            briefkastenNr
            sensor1
            kaufdatumSensor1
            sensor2
            kaufdatumSensor2
            magnetkarte
            ausgabedatumMagnetkarte
            gebrauchteSensoren
            kfzKennzeichen
            erschliessungskosten
            ausgeschiedenAm
            stromzaehlerart
            pachtPaechter {
                pachtbeginn
                parzelle {
                    id
                    standort
                }
            }
        }
    }
`;


export const CREATE_PAECHTER = gql`
    mutation CreatePaechter(
        $anrede: String!,
        $name: String!,
        $vorname: String!,
        $strasse: String!,
        $plz: String!,
        $ort: String!,
        $vorherigeMeldeadresse: String!,
        $telefonFestnetz: String!,
        $telefonMobil: String!,
        $geburtsdatum: Date!,
        $namePartner: String!,
        $geburtsname: String!,
        $geburtsdatumPartner: Date,
        $eingetragenInPachtvertrag: Boolean!,
        $ausweisnummerPaechter: String!,
        $ausweisnummerPartner: String!,
        $weitereKontaktpersonen: String!,
        $iban: String!,
        $zusatzpflege: String!,
        $auflagen: String!,
        $auflagenfrist: Date,
        $antragPv: Date,
        $pruefungPv: Date,
        $trennschalterPv: Boolean!,
        $duldungen: String!,
        $briefkastenNr: String!,
        $sensor1: String!,
        $kaufdatumSensor1: Date,
        $sensor2: String!,
        $kaufdatumSensor2: Date,
        $magnetkarte: String!,
        $ausgabedatumMagnetkarte: Date,
        $gebrauchteSensoren: Int,
        $kfzKennzeichen: String!,
        $erschliessungskosten: String,
        $stromzaehlerart: String,
        ) {
            createPaechter(
                anrede: $anrede,
                name: $name,
                vorname: $vorname,
                strasse: $strasse,
                plz: $plz,
                ort: $ort,
                vorherigeMeldeadresse: $vorherigeMeldeadresse,
                telefonFestnetz: $telefonFestnetz,
                telefonMobil: $telefonMobil,
                geburtsdatum: $geburtsdatum,
                namePartner: $namePartner,
                geburtsname: $geburtsname,
                geburtsdatumPartner: $geburtsdatumPartner,
                eingetragenInPachtvertrag: $eingetragenInPachtvertrag,
                ausweisnummerPaechter: $ausweisnummerPaechter,
                ausweisnummerPartner: $ausweisnummerPartner, 
                weitereKontaktpersonen: $weitereKontaktpersonen,
                iban: $iban, zusatzpflege: $zusatzpflege,
                auflagen: $auflagen,
                auflagenfrist: $auflagenfrist,
                antragPv: $antragPv,
                pruefungPv: $pruefungPv,
                trennschalterPv: $trennschalterPv,
                duldungen: $duldungen,
                briefkastenNr: $briefkastenNr,
                sensor1: $sensor1,
                kaufdatumSensor1: $kaufdatumSensor1,
                sensor2: $sensor2,
                kaufdatumSensor2: $kaufdatumSensor2,
                magnetkarte: $magnetkarte,
                ausgabedatumMagnetkarte: $ausgabedatumMagnetkarte,
                gebrauchteSensoren: $gebrauchteSensoren,
                kfzKennzeichen: $kfzKennzeichen,
                erschliessungskosten: $erschliessungskosten,
                stromzaehlerart: $stromzaehlerart,
                ) {
                    id
                    anrede
                    name
                    vorname
                    strasse
                    plz
                    ort
                    vorherigeMeldeadresse
                    telefonFestnetz
                    telefonMobil
                    geburtsdatum
                    namePartner
                    geburtsname
                    geburtsdatumPartner
                    eingetragenInPachtvertrag
                    ausweisnummerPaechter
                    ausweisnummerPartner
                    weitereKontaktpersonen
                    iban
                    zusatzpflege
                    auflagen
                    auflagenfrist
                    antragPv
                    pruefungPv
                    trennschalterPv
                    duldungen
                    briefkastenNr
                    sensor1
                    kaufdatumSensor1
                    sensor2
                    kaufdatumSensor2
                    magnetkarte
                    ausgabedatumMagnetkarte
                    gebrauchteSensoren
                    kfzKennzeichen
                    erschliessungskosten
                    stromzaehlerart
            }
    }
`;

export const UPDATE_PAECHTER = gql`
    mutation UpdatePaechter(
        $id: ID!,
        $anrede: String!,
        $name: String!,
        $vorname: String!,
        $strasse: String!,
        $plz: String!,
        $ort: String!,
        $vorherigeMeldeadresse: String!,
        $telefonFestnetz: String!,
        $telefonMobil: String!,
        $geburtsdatum: Date!,
        $namePartner: String!,
        $geburtsname: String!,
        $geburtsdatumPartner: Date,
        $eingetragenInPachtvertrag: Boolean!,
        $ausweisnummerPaechter: String!,
        $ausweisnummerPartner: String!,
        $weitereKontaktpersonen: String!,
        $iban: String!,
        $zusatzpflege: String!,
        $auflagen: String!,
        $auflagenfrist: Date,
        $antragPv: Date,
        $pruefungPv: Date,
        $trennschalterPv: Boolean!,
        $duldungen: String,
        $briefkastenNr: String!,
        $sensor1: String,
        $kaufdatumSensor1: Date,
        $sensor2: String,
        $kaufdatumSensor2: Date,
        $magnetkarte: String,
        $ausgabedatumMagnetkarte: Date,
        $gebrauchteSensoren: Int,
        $kfzKennzeichen: String,
        $erschliessungskosten: String,
        $ausgeschiedenAm: Date,
        $stromzaehlerart: String,
        ) {
            updatePaechter(
                id: $id,
                anrede: $anrede,
                name: $name,
                vorname: $vorname,
                strasse: $strasse,
                plz: $plz,
                ort: $ort,
                vorherigeMeldeadresse: $vorherigeMeldeadresse,
                telefonFestnetz: $telefonFestnetz,
                telefonMobil: $telefonMobil,
                geburtsdatum: $geburtsdatum,
                namePartner: $namePartner,
                geburtsname: $geburtsname,
                geburtsdatumPartner: $geburtsdatumPartner,
                eingetragenInPachtvertrag: $eingetragenInPachtvertrag,
                ausweisnummerPaechter: $ausweisnummerPaechter,
                ausweisnummerPartner: $ausweisnummerPartner, 
                weitereKontaktpersonen: $weitereKontaktpersonen,
                iban: $iban,
                zusatzpflege: $zusatzpflege,
                auflagen: $auflagen,
                auflagenfrist: $auflagenfrist,
                antragPv: $antragPv,
                pruefungPv: $pruefungPv,
                trennschalterPv: $trennschalterPv,
                duldungen: $duldungen,
                briefkastenNr: $briefkastenNr,
                sensor1: $sensor1,
                kaufdatumSensor1: $kaufdatumSensor1,
                sensor2: $sensor2,
                kaufdatumSensor2: $kaufdatumSensor2,
                magnetkarte: $magnetkarte,
                ausgabedatumMagnetkarte: $ausgabedatumMagnetkarte,
                gebrauchteSensoren: $gebrauchteSensoren,
                kfzKennzeichen: $kfzKennzeichen,
                erschliessungskosten: $erschliessungskosten,
                ausgeschiedenAm: $ausgeschiedenAm,
                stromzaehlerart: $stromzaehlerart,
                ) {
                    id
                    anrede
                    name
                    vorname
                    strasse
                    plz
                    ort
                    vorherigeMeldeadresse
                    telefonFestnetz
                    telefonMobil
                    geburtsdatum
                    namePartner
                    geburtsname
                    geburtsdatumPartner
                    eingetragenInPachtvertrag
                    ausweisnummerPaechter
                    ausweisnummerPartner
                    weitereKontaktpersonen
                    iban
                    zusatzpflege
                    auflagen
                    auflagenfrist
                    antragPv
                    pruefungPv
                    trennschalterPv
                    duldungen
                    briefkastenNr
                    sensor1
                    kaufdatumSensor1
                    sensor2
                    kaufdatumSensor2
                    magnetkarte
                    ausgabedatumMagnetkarte
                    gebrauchteSensoren
                    kfzKennzeichen
                    erschliessungskosten
                    ausgeschiedenAm
                    stromzaehlerart
            }
    }
`;


export const DELETE_PAECHTER = gql`
    mutation DeletePaechter($id: ID!){
        deletePaechter(id: $id) {
            ok
        }
    }
`;
