import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_PAECHTER } from '../Paechter/graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 20,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30, // Set a minimum height for all rows
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#ffffff', // White for odd rows
    },
    cell: {
        padding: 2,
    },
    name: {
        width: '15%',
    },
    vorname: {
        width: '15%',
    },
    auflagen: {
        width: '30%',
    },
    auflagenfrist: {
        width: '15%',
        paddingLeft: 15,
    },
    duldungen: {
        width: '25%',
    },
});

const Auflagenfristen = () => {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER);

    if (loading) return <LoadingAnimation/>;

    function formatDate(inputDate) {
        if (inputDate) {
            // Parse the input date string
            const dateParts = inputDate.split('-');
            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];
            
            // Construct the formatted date string
            const formattedDate = `${day}.${month}.${year}`;
        
            return formattedDate;
        }
        return null;
    }

    const paechterList = data ? 
    data.paechter
        .filter(item => !item.name.toLowerCase().includes('mob3ls'))
        .map(item => ({
            name: item.name,
            vorname: item.vorname,
            auflagen: item.auflagen,
            auflagenfrist: formatDate(item.auflagenfrist),
            duldungen: item.duldungen,
        })) 
    : [];

    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.vorname]}>Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.auflagen]}>Auflagen</Text>
                            <Text style={[styles.cell, styles.header, styles.auflagenfrist]}>Auflagenfrist</Text>
                            <Text style={[styles.cell, styles.header, styles.duldungen]}>Duldungen</Text>
                        </View>

                        {/* Render rows */}
                        {paechterList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.name]} wrap>{item.name}</Text>
                                <Text style={[styles.cell, styles.vorname]} wrap>{item.vorname}</Text>
                                <Text style={[styles.cell, styles.auflagen]} wrap>{item.auflagen}</Text>
                                <Text style={[styles.cell, styles.auflagenfrist]} wrap>{item.auflagenfrist}</Text>
                                <Text style={[styles.cell, styles.duldungen]} wrap>{item.duldungen}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Auflagenfristen;
