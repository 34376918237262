import * as React from 'react';
import { useQuery } from '@apollo/client';

import {GET_ZAEHLER, DELETE_ZAEHLER} from './graphql'
import Title from '../Utils/Title'
import LoadingAnimation from '../Utils/LoadingAnimation';

import ContextMenu from './ContextMenu';
import DeleteZaehler from './Delete';
import AlertMessage from '../Alerts/AlertMessage';

import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';



function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}

function formatDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}


const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);





export default function ListZaehler() {
    
    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_ZAEHLER, {
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const zaehlerList = data? data.zaehler.map(item => {
        const id = item.id;
        const zaehlernummer = item.zaehlernummer;
        const parzelle = item.parzelle?.standort;
        const energieart = item.energieart;
        const installationAm = item.installationAm;
        const deinstallationAm = item.deinstallationAm;
        const letzteEichung = item.letzteEichung;
        const zaehlerwechsel = item.zaehlerwechsel;
        
        return { 
            id,
            zaehlernummer,
            parzelle,
            energieart,
            installationAm,
            deinstallationAm,
            letzteEichung,
            zaehlerwechsel
        };
    }) : '';

    const rows = zaehlerList;

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }
    

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 50 },
        { field: 'zaehlernummer', headerName: 'Zählernummer', width: 200 },
        { field: 'parzelle', headerName: 'Parzelle', width: 250 },
        { field: 'energieart', headerName: 'Energieart', width: 170, },
        { field: 'installationAm', headerName: 'Installation am', width: 170, type: 'date', valueFormatter: (params) => formatDate(params), },
        { field: 'deinstallationAm', headerName: 'Deinstallation am', width: 170, type: 'date', valueFormatter: (params) => formatDate(params), },
        { field: 'zaehlerwechsel', headerName: 'Zählerwechsel', width: 170, type: 'boolean', },
        { field: 'letzteEichung', headerName: 'Letzte Eichung', type: 'number', width: 150, align: 'center', 
            valueFormatter: (params) => {
                if(params.value) {
                    return String(params.value);
                };
            }, 
        },
        {
            field: "actions",
            headerName:
                <>
                <Tooltip title="Zähler anlegen">
                    <Link to={`/energie/zaehler/create`}>
                        <IconButton>
                            <AddBoxIcon
                                variant='filled'
                                
                                color='action'
                                sx={{
                                    '&:hover': {
                                        color:'#233044'
                                    },
                                    marginLeft: '0.1rem',
                                    fontSize: '1.25rem'
                                }}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenu
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];
    
    return (
        <>
        
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Zähler</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/energie/energieverbraeuche">
                                Energieverbräuche
                            </Button>

                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/energie/energiepreise">
                                Energiepreise
                            </Button>
                        
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/energie/zaehlerstaende">
                                Zählerstände
                            </Button>
                        </div>
                    </div>


                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'parzelle', sort: 'asc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingState}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}