import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_PARZELLEN } from './graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 20,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30, // Set a minimum height for all rows
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#ffffff', // White for odd rows
    },
    cell: {
        padding: 2,
    },
    gasdruckpruefung: {
        width: '20%',
    },
    parzelle: {
        width: '20%',
    },
    name: {
        width: '20%',
    },
    vorname: {
        width: '15%',
    },
    geplanteGasdruckpruefung: {
        width: '25%',
    },
});

const FaelligeGasdruckpruefungen = () => {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN);

    if (loading) return <LoadingAnimation/>;


    // Da wir ein pachtSet, also ein array of Pachten, geliefert bekommen, müssen wir
    // das Pachtende filtern; dieses muss grösser als heute sein
    // Ausserdem sollen nur Parzellen ausgegeben werden, deren letzte Gasdruckprüfung länger als 2 Jahr her ist
    const today = new Date();
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(today.getFullYear() - 2); // modifies the twoYearsAgo date object to be two years in the past, but it doesn't affect the today object

    function formatDate(inputDate) {
        if (inputDate) {
            const dateParts = inputDate.split('-');
            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];
            const formattedDate = `${day}.${month}.${year}`;
            return formattedDate;
        }
        return null;        
    }

    const parzellenList = data ?
    data.parzellen
        .filter(item => new Date(item.gasdruckpruefung) < twoYearsAgo)
        .filter(item => !item.standort.toLowerCase().includes('test'))
        .filter(item => !item.stellplatzparzelle)
        .map(item => ({
            gasdruckpruefung: formatDate(item.gasdruckpruefung),
            parzelle: item.standort,
            name: item.pachtSet.filter(pacht => {
                const pachtende = new Date(pacht.pachtende);
                return pachtende > today;
            })[0]?.paechter.name,
            vorname: item.pachtSet.filter(pacht => {
                const pachtende = new Date(pacht.pachtende);
                return pachtende > today;
            })[0]?.paechter.vorname,
            geplanteGasdruckpruefung: formatDate(item.geplanteGasdruckpruefung)
        }))
        .filter(item => item.name) // exclude items with null or empty name
    : [];


    // sort by parzelle
    parzellenList.sort((a, b) => {
        let paechterA = a.parzelle.toLowerCase();
        let paechterB = b.parzelle.toLowerCase();
        
        if (paechterA < paechterB) return -1;
        if (paechterA > paechterB) return 1;
        return 0;
    });


    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.gasdruckpruefung]}>Prüfung erfolgt am</Text>
                            <Text style={[styles.cell, styles.header, styles.parzelle]}>Parzelle</Text>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.vorname]}>Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.geplanteGasdruckpruefung]}>Prüfung geplant am</Text>
                        </View>

                        {/* Render rows */}
                        {parzellenList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.gasdruckpruefung]} wrap>{item.gasdruckpruefung}</Text>
                                <Text style={[styles.cell, styles.parzelle]} wrap>{item.parzelle}</Text>
                                <Text style={[styles.cell, styles.name]} wrap>{item.name}</Text>
                                <Text style={[styles.cell, styles.vorname]} wrap>{item.vorname}</Text>
                                <Text style={[styles.cell, styles.geplanteGasdruckpruefung]} wrap>{item.geplanteGasdruckpruefung}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default FaelligeGasdruckpruefungen;
