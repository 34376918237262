import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_PACHT } from './graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 20,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30, // Set a minimum height for all rows
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#ffffff', // White for odd rows
    },
    cell: {
        padding: 2,
    },
    name: {
        width: '25%',
    },
    vorname: {
        width: '15%',
    },
    telefon: {
        width: '20%',
    },
    mobil: {
        width: '20%',
    },
    weitereKontaktpersonen: {
        width: '20%',
    },
});

const Telefonliste = () => {
    const { loading, error, data, refetch } = useQuery(GET_PACHT);

    if (loading) return <LoadingAnimation/>;

    const twoMonthAgo = new Date();
    twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 2);
    // Handle edge case when current month is January
    if (twoMonthAgo.getMonth() === 11) {
    twoMonthAgo.setFullYear(twoMonthAgo.getFullYear() - 1);
    }

    console.log('data: ', data);

    const pachtList = data ? data.pacht
        // Pächter rausfiltern, deren Pacht vor mehr als 2 Monaten abgelaufen ist - auf Wunsch von Frau Feldmann vom 24.04.24
        .filter(item => (item.pachtende && new Date(item.pachtende) > twoMonthAgo) || !item.pachtende)
        // Testpächter rausfiltern
        .filter(item => item.paechter && item.paechter.name && !item.paechter.name.toLowerCase().includes('test'))
        // Verwaltungs- u. Stellplatzparzellen rausfiltern
        .filter(item => item.parzelle.stellplatzparzelle === false && item.parzelle.verwaltungsparzelle === false)
        .map(item => ({
            name: item.paechter?.name || '',
            vorname: item.paechter?.vorname || '',
            telefon: item.paechter?.telefonFestnetz || '',
            mobil: item.paechter?.telefonMobil || '',
            weitereKontaktpersonen: item.paechter?.weitereKontaktpersonen || [],
        }))
        // Remove duplicates
        .filter((item, index, self) => 
            index === self.findIndex((t) => (
                t.name === item.name && t.vorname === item.vorname
            ))
        )
        .sort((a, b) => {
            let nameA = a.name.toLowerCase();
            let nameB = b.name.toLowerCase();
            
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            let vornameA = a.vorname.toLowerCase();
            let vornameB = b.vorname.toLowerCase();
            
            if (vornameA < vornameB) return -1;
            if (vornameA > vornameB) return 1;

            return 0;
        })
        : [];

    console.log('pachtList: ', pachtList);

    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.vorname]}>Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.telefon]}>Telefon</Text>
                            <Text style={[styles.cell, styles.header, styles.mobil]}>Mobil</Text>
                            <Text style={[styles.cell, styles.header, styles.weitereKontaktpersonen]}>Weitere Kontaktpersonen</Text>
                        </View>

                        {/* Render rows */}
                        {pachtList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.name]} wrap={false}>{item.name}</Text>
                                <Text style={[styles.cell, styles.vorname]} wrap={false}>{item.vorname}</Text>
                                <Text style={[styles.cell, styles.telefon]} wrap={false}>{item.telefon}</Text>
                                <Text style={[styles.cell, styles.mobil]} wrap={false}>{item.mobil}</Text>
                                <Text style={[styles.cell, styles.weitereKontaktpersonen]} wrap={false}>{item.weitereKontaktpersonen}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Telefonliste;
