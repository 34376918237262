import * as React from 'react';
import { useParams } from "react-router-dom";
import { useMutation, gql, useQuery, useApolloClient, ApolloClient , ApolloProvider} from '@apollo/client';
import {GET_PAECHTER} from './graphql';

import { GET_PARZELLEN } from '../Parzellen/graphql';

import { useNavigate } from "react-router-dom";
// date manipulation and validation/parsing, see https://www.npmjs.com/package/date-fns
import { Link } from "react-router-dom";

import Autocomplete from '@mui/material/Autocomplete';
import Title from '../Utils/Title'
import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';



let loads;

function GetPaechter() {
    const { loading, error, data, refetch } = useQuery(GET_PAECHTER, {
        onCompleted: () => {
            loads=false;
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        return loads;
    };

    if (error) return <p>Error :(</p>;
    console.log(data.paechter);
    return data.paechter;
}


function GetParzellen() {
    //const [loads, setLoading] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN, {
        onCompleted: () => {
            loads=false;
            //console.log('completed. loads: ' + loads);
            return loads;
        }
    });
  
    if (loading) {
        loads = true;
        //console.log('loading...loads: ' + loads);
        return loads;
    };
    if (error) return <p>Error :(</p>;
    console.log(data);
    console.log(data.parzellen);
    return data.parzellen;
    
}




// fomat chached date to dd.MM.yyyy
function formatCachedDate(date) {
    if (date) {
        date = date.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
    } else {
        // important: set date to empty string - otherwise yup will make field required!!
        date = '';
    }
    return date;
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
}


export default function ShowPaechter() {

    const params = useParams();
    const paechterID = params.id;
    const paechter = GetPaechter();

    // We need to get the data of Parzellen for the apollo cache to navigate between the queries of different objects
    const parzellenAbruf = GetParzellen();

    const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    const handleEditButton = () => {
        navigate(`/paechter/${paechterID}/update`);
    }


    // filter to get paechter with id
    const filtered = Object.values(paechter).filter(paechter => paechter.id === paechterID);
    console.log(filtered);
    console.log(filtered[0].pachtPaechter);

    // get parzellen    
    const parzellenstandorte = (val) => { 
        if(val !== '') {
            // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
            return val.map( (item)=>item.parzelle);
        }
    }
    const parzelleDataInner = parzellenstandorte(filtered[0].pachtPaechter);
    console.log(parzelleDataInner);

    /*
    // get parzellen    
    const parzellen = (val) => { 
        if(val !== '') {
            // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
            return val.map( (item)=>item.parzellen);
        }
    }
    const parzelleData = parzellen(filtered);
    const parzelleDataInner = parzelleData[0];
    */


    /*
    // get mobilheime    
    const mobilheime = (val) => { 
        if(val !== '') {
            // get id's of array of objects, see https://stackoverflow.com/a/64110076/2732698
            return val.map( (item)=>item.mobilheimSet);
        }
    }

    console.log(mobilheime);

    const mobilheimData = mobilheime(filtered);
    console.log(mobilheimData);
    const mobilheimDataInner = mobilheimData[0];
    console.log(mobilheimDataInner);

    */


    const LinkTextFieldParzelle = () => {

        const inputStyles = {
            height: '56px',
        };

        return (
          <TextField
            id="link-textfield"
            label="Parzelle(n)"
            variant="filled"
            fullWidth
            disabled
            size="medium"
            InputProps={{
              inputComponent: ParzelleStandorte,
              style: inputStyles,
            }}
            value={ParzelleStandorte}
            // Add any other props as needed
            InputLabelProps={{
                shrink: parzelleDataInner.length > 0 ? true : false,
            }}
          />
        );
    };
    
    const ParzelleStandorte = () => (
            parzelleDataInner.map(item => {
                return (
                    <Typography component="div" className="reference-link">
                        <Link to={`/parzelle/${item.id}/show`}>
                            {item.standort} 
                        </Link>
                    </Typography>
                )
            })
    );

    

    
    
    // Bei den Mobilheimen brauchen wir nur die ID des/der Mobilheim(e)s, da aufgrund der one-to-one-Beziehung des Mobilheinms zur Parzelle
    //  der Standort der Parzelle auch gleichzeitig den Standort des Mobilheims darstellt
    
    // count how many mobilheime. If at least one mobilheim, "shrink" (line 215) has to be true

    /*
    const mobilheimObjects = parzelleDataInner.filter(item => item.mobilheim !== null);
    const countOfMobilheim = mobilheimObjects.length;
    
    const LinkTextFieldMobilheim = () => {

        const inputStyles = {
            height: '56px',
        };

        return (
          <TextField
            id="link-textfield"
            label="Mobilheim(e)"
            variant="filled"
            fullWidth
            disabled
            size="medium"
            InputProps={{
              inputComponent: MobilheimStandorte,
              style: inputStyles,
            }}
            value={MobilheimStandorte}
            // Add any other props as needed
            InputLabelProps={{
                shrink: countOfMobilheim > 0 ? true : false,
            }}
          />
        );
    };

    
    var Mobilheimcounter = false;

    const MobilheimStandorte = () => (
        parzelleDataInner.map(item => {
            if (item.mobilheim) {
                Mobilheimcounter = true;
                console.log(Mobilheimcounter);
                return (
                    <Typography component="div" className="reference-link">
                        <Link to={`/mobilheim/${item.mobilheim.id}/show`}>
                            {item.standort} 
                        </Link>
                    </Typography>
                )
            }
            return null;
        })
    );

    < LinkTextFieldParzelle />
    < LinkTextFieldMobilheim />
    
    */
    
    

    // set const for 'Eingetragen in PV'    
    function formatBoolEingetragenInPV() {
        if(filtered.map(paechter => {return (paechter.eingetragenInPachtvertrag)}).toString() == 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }
    const formatEingetragenInPV = formatBoolEingetragenInPV();


    // set const for 'Trennschalter PV'    
    function formatBoolTrennschalter() {
        if(filtered.map(paechter => {return (paechter.trennschalterPV)}).toString() == 'true') {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }
    const formatTrennschalterPV = formatBoolTrennschalter();





    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Grid
                container
                spacing={3}
                alignItems="center"
                className='show'
            >
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                        <Box
                            sx={{
                                                                    
                            }}
                            noValidate
                        >
                            
                            {filtered.map(paechter => {
                                return (
                                    <>
                                        <Title>Pächter "{paechter.vorname} {paechter.name}" anzeigen</Title>

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Anrede"
                                            value={paechter.anrede}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Nachname"
                                            value={paechter.name}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Vorname"
                                            value={paechter.vorname}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Strasse und Hausnummer"
                                            value={paechter.strasse}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="PLZ"
                                            value={paechter.plz}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Ort"
                                            value={paechter.ort}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Vorherige Meldeadresse"
                                            value={paechter.vorherigeMeldeadresse}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Telefon Festnetz"
                                            value={paechter.telefonFestnetz}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Telefon Mobil"
                                            value={paechter.telefonMobil}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Geburtsdatum"
                                            value={formatCachedDate(paechter.geburtsdatum)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Name Partner"
                                            value={paechter.namePartner}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Geburtsname"
                                            value={paechter.geburtsname}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Geburtsdatum Partner"
                                            value={formatCachedDate(paechter.geburtsdatumPartner)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Eingetragen in Pachtverzeichnis"
                                            value={formatEingetragenInPV}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Ausweisnummer Pächter"
                                            value={paechter.ausweisnummerPaechter}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Ausweisnummer Partner"
                                            value={paechter.ausweisnummerPartner}
                                        />
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            disabled
                                            variant='filled'
                                            label="Weitere Kontaktpersonen"
                                            value={paechter.weitereKontaktpersonen}
                                        />
                                        
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="IBAN"
                                            value={paechter.iban}
                                        />
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            disabled
                                            variant='filled'
                                            label="Zusatzpflege"
                                            value={paechter.zusatzpflege}
                                        />
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={10}
                                            disabled
                                            variant='filled'
                                            label="Auflagen"
                                            value={paechter.auflagen}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Auflagenfrist"
                                            value={formatCachedDate(paechter.auflagenfrist)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Stromzählerart"
                                            value={paechter.stromzaehlerart}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Antrag PV"
                                            value={formatCachedDate(paechter.antragPV)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Prüfung PV"
                                            value={formatCachedDate(paechter.pruefungPV)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Trennschalter PV"
                                            value={formatTrennschalterPV}
                                        />
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={5}
                                            disabled
                                            variant='filled'
                                            label="Duldungen"
                                            value={paechter.duldungen}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Briefkasten Nr."
                                            value={paechter.briefkastenNr}
                                        />
                                        
                                        < LinkTextFieldParzelle />

                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Sensor 1"
                                            value={paechter.sensor1}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Kaufdatum Sensor 1"
                                            value={formatCachedDate(paechter.kaufdatumSensor1)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Sensor 2"
                                            value={paechter.sensor2}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Kaufdatum Sensor 2"
                                            value={formatCachedDate(paechter.kaufdatumSensor2)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Magnetkarte"
                                            value={paechter.magnetkarte}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Ausgabedatum Magnetkarte"
                                            value={formatCachedDate(paechter.ausgabedatumMagnetkarte)}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Gebrauchte Sensoren"
                                            value={paechter.gebrauchteSensoren}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="KFZ-Kennzeichen"
                                            value={paechter.kfzKennzeichen}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Erschließungskosten (EUR)"
                                            value={paechter.erschliessungskosten}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled
                                            variant='filled'
                                            label="Ausgeschieden am"
                                            value={formatCachedDate(paechter.ausgeschiedenAm)}
                                        />
                                    </>                                    
                                );
                                })
                            }
                            <Button color="primary" variant="contained" onClick={handleEditButton}>
                                bearbeiten
                            </Button>
                            <Button color="primary" variant="contained" className='backbutton' onClick={goBack}>
                                zurück
                            </Button>
                            
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};