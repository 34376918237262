import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_PARZELLEN } from './graphql';
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 20,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30, // Set a minimum height for all rows
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#ffffff', // White for odd rows
    },
    cell: {
        padding: 2,
    },
    parzelle: {
        width: '20%',
    },
    name: {
        width: '20%',
    },
    vorname: {
        width: '15%',
    },
    besonderheiten: {
        width: '20%',
    },
    geplanteGasdruckpruefung: {
        width: '25%',
    },
});

const GeplanteGasdruckpruefungen = () => {
    const { loading, error, data, refetch } = useQuery(GET_PARZELLEN);

    if (loading) return <LoadingAnimation/>;

    // Da wir ein pachtSet, also ein array of Pachten, geliefert bekommen, müssen wir
    // das Pachtende filtern; dieses muss grösser als heute sein
    // Ausserdem sollen nur Parzellen ausgegeben werden, die auch eine geplante Gasdruckprüfung aufweisen
    const today = new Date();

    function formatDate(inputDate) {
        // Parse the input date string
        const dateParts = inputDate.split('-');
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];
        
        // Construct the formatted date string
        const formattedDate = `${day}.${month}.${year}`;
        
        return formattedDate;
    }

    const parzellenList = data ?
    data.parzellen
        .filter(item => item.geplanteGasdruckpruefung !== null)
        .filter(item => item.standort.toLowerCase() !== 'test')
        .filter(item =>{
            const datumGasdruckprüfung = new Date(item.geplanteGasdruckpruefung);
            return datumGasdruckprüfung > today;
        })
        .map(item => ({
            parzelle: item.standort,
            name: item.pachtSet.filter(pacht => {
                const pachtende = new Date(pacht.pachtende);
                return pachtende > today;
            })[0]?.paechter.name,
            vorname: item.pachtSet.filter(pacht => {
                const pachtende = new Date(pacht.pachtende);
                return pachtende > today;
            })[0]?.paechter.vorname,
            besonderheiten: item.besonderheiten,
            geplanteGasdruckpruefung: formatDate(item.geplanteGasdruckpruefung)
        }))
    : [];

    // sort by parzelle
    parzellenList.sort((a, b) => {
        let paechterA = a.parzelle.toLowerCase();
        let paechterB = b.parzelle.toLowerCase();
        
        if (paechterA < paechterB) return -1;
        if (paechterA > paechterB) return 1;
        return 0;
    });


    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.parzelle]}>Parzelle</Text>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.vorname]}>Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.besonderheiten]}>Besonderheiten</Text>
                            <Text style={[styles.cell, styles.header, styles.geplanteGasdruckpruefung]}>Geplante Gasdruckprüfung</Text>
                        </View>

                        {/* Render rows */}
                        {parzellenList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.parzelle]} wrap>{item.parzelle}</Text>
                                <Text style={[styles.cell, styles.name]} wrap>{item.name}</Text>
                                <Text style={[styles.cell, styles.vorname]} wrap>{item.vorname}</Text>
                                <Text style={[styles.cell, styles.besonderheiten]} wrap>{item.besonderheiten}</Text>
                                <Text style={[styles.cell, styles.geplanteGasdruckpruefung]} wrap>{item.geplanteGasdruckpruefung}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default GeplanteGasdruckpruefungen;
