
import * as React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useMutation } from '@apollo/client';
import { GET_MAHNUNGEN, CREATE_MAHNUNGEN } from '../Mahnungen/graphql';
import { UPDATE_PREISE_BESTANDSPACHTEN } from '../Pacht/graphql';

import Chart from '../test/Chart';
import Deposits from '../test/Deposits';
import Orders from '../test/Orders';

// <Chart />
// <Deposits />

export default function Dashboard() {

    const [createMahnung, { data, loading, error, refetch }] = useMutation(CREATE_MAHNUNGEN, {
        fetchPolicy: 'network-only',
        refetchQueries: [{ query: GET_MAHNUNGEN }],
        errorPolicy: 'all',
        onError: () => {
            console.log('Fehler in onError in mutation');
        },
        onCompleted: (data) => {
            if (Boolean(data.createMahnung?.ok)) {
                //assignAlertMessage('Mahnungen aktualisiert');
                //assignAlertSeverity('success');
            }
        },
    });


    const [updatePreiseBestandspachten] = useMutation(UPDATE_PREISE_BESTANDSPACHTEN, {
        fetchPolicy: 'network-only',
    });


    
    React.useEffect(() => {
        createMahnung();
        updatePreiseBestandspachten();
        if (error) { 
            if (error.graphQLErrors) {
                const { message, locations, path } = error.graphQLErrors[0];
                assignAlertMessage(message);
                assignAlertSeverity('error');
            }
            else if (error.networkError) {
                assignAlertMessage(networkError);
                assignAlertSeverity('error');
            }
            else {
                assignAlertMessage('Fehler! Bitte den Support kontaktieren.');
                assignAlertSeverity('error');
            }
        }
    }, [error]);



    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                
                {/* Recent Orders */}
                <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Orders />
                </Paper>
                </Grid>
            </Grid>

        </Container>
    );
}




